import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink";

const ar = {
  title: 'هل ترغب في الحصول على منتجات عالية الجودة من أفضل مصنع صيني؟',
  content: 'سيساعدك الوكيل المحترف في العثور على المصانع، والحصول على أفضل الأسعار، ودعمك طوال الطريق حتى وصول المنتجات إلى باب منزلك.',
  action: 'احصل على وكيل الآن'
}


const BlogContactCard = ({lang }) => {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <h5 className="card-title">{lang? ar.title : 'Want to get quality products from the best Chinese factory?'}</h5>
        <p className="card-text">{ lang ? ar.content : 'A professional agent will help you find factories, get the best quotes, support you all the way until products arriving your doorstep.'}</p>
        <div className="learn-more" style={{fontSize: '14px'}}>
          <AniLink to={`/contact-us`} duration={0.3} hex="#9dc428" paintDrip>
            { lang ? ar.action : 'Get an agent now'}
          </AniLink>
        </div>
      </div>
    </div>
  )
}

export default BlogContactCard
