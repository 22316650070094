import React from "react";
import * as styles from "./blog.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {useLang} from "../../hooks/useLang";

const tags = ['LED lighting', 'Plant switch', 'Extesio socket', 'Certification knowledge', 'Import&Export Knowledg']
const tagArs = ['اضاءة الصمام الثنائي الباعث للضوء', 'مفتاح الوحة', 'مقبس التوصيل', 'شهادة المعرفة', 'معرفة الاستيراد والتصدير']

const BlogCategories = ({ lang }) => {
  const langLink = useLang()

  return (
    <div className={styles.recent}>
      <h5>{ lang === 'ar' ? 'الفئات' : 'Categories'}</h5>
      <div>
        <ul>
          {
            (lang === 'ar' ? tagArs : tags).map((x, index) => {
              return (
                <li key={index}>
                  <AniLink to={`${langLink}/blogs?tag=${x}`} duration={0.3} hex="#9dc428" paintDrip>
                    <span style={{ color: '#6c757d'}}>{x}</span>
                  </AniLink>
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  )
}

export default BlogCategories
