import React from "react";
import Layout from "../components/layout";
import * as styles from './blogs.module.css'
import cls from "classnames"
import RecentPost from "../components/blog/recent-post";
import {graphql} from "gatsby";
import get from "lodash/get";
import AniLink from "gatsby-plugin-transition-link/AniLink"
import BlogContactCard from "../components/blog/blog-contact-us";
import BlogCategories from "../components/blog/categories-post";
import SEO from "../components/seo";

const Blogs = ({data, location}) => {
  const blogs = get(data, 'allContentfulBlog.nodes')
  const recentPosts = get(data, 'allContentfulRecentPost.nodes')
  const allKeywords = get(data, 'allContentfulPageKeywords.nodes')
  const bannerLink = get(data, 'contentfulAsset.file.url')
  const params = new URLSearchParams(location.search);
  const tag = params.get("tag");
  const renderBlogs = tag ? blogs.filter(x => x.categories === tag || x.categories.includes(tag)) : blogs

  return (
    <Layout location={location}>
      <SEO title={'HomeBest Blog /E-commerce in the middle east'} keywords={allKeywords[0]?.keywords}/>
      <div className={styles.bannerWp}>
        <img src={bannerLink} className="img-fluid" alt="Blogs Page Banner"/>
      </div>
      <div className="container p-4">
        <div className="d-flex">
          {/*<div className={styles.blogsVideo}>*/}
          {/*  <iframe*/}
          {/*    width="300"*/}
          {/*    height="169"*/}
          {/*    src="https://www.youtube.com/embed/BBX5qh09OIE"*/}
          {/*    frameBorder="0"*/}
          {/*    style={{borderRadius: '10px'}}*/}
          {/*    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
          {/*    allowFullScreen/>*/}
          {/*</div>*/}
          {/*<div className={styles.blogsVideo}>*/}
          {/*  <iframe*/}
          {/*    width="300"*/}
          {/*    height="169"*/}
          {/*    src="https://www.youtube.com/embed/BBX5qh09OIE"*/}
          {/*    frameBorder="0"*/}
          {/*    style={{borderRadius: '10px'}}*/}
          {/*    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
          {/*    allowFullScreen/>*/}
          {/*</div>*/}
          {/*<div className={styles.blogsVideo}>*/}
          {/*  <iframe*/}
          {/*    width="300"*/}
          {/*    height="169"*/}
          {/*    src="https://www.youtube.com/embed/BBX5qh09OIE"*/}
          {/*    frameBorder="0"*/}
          {/*    style={{borderRadius: '10px'}}*/}
          {/*    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
          {/*    allowFullScreen/>*/}
          {/*</div>*/}
        </div>
        <div className="mt-5 row bg-transparent g-6">
          <div className="col-md-9 row bg-transparent gx-4">
            {
              renderBlogs.length > 0 && renderBlogs.map(m => {
                return (
                  <div className='col-md-12 col-lg-6 col-lg-6 pb-3' key={m.id}>
                    <div className={"card border-0"}>
                      <AniLink to={`/blogs/${m.urlName}`} duration={0.3} hex="#9dc428" paintDrip>
                        {
                          m.button?.file.url &&
                          <img src={m.button.file.url} className={styles.blogsItemImg} alt="..."/>
                        }
                        <div className={cls("card-body", styles.blogItemColor)}>
                          <h5 className="card-title">{m.title}</h5>
                          <p className="card-text">{m.description}</p>
                        </div>
                      </AniLink>
                    </div>
                  </div>
                )
              })
            }
            {
              renderBlogs.length === 0 && <div className={'p-5'}>Not Found Blogs</div>
            }
          </div>
          <div className="col-md-3">
            <BlogContactCard />
            <RecentPost posts={recentPosts} />
            <BlogCategories />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Blogs

export const pageQuery = graphql`
  query BlogsQuery {
    allContentfulBlog(filter: {node_locale: {eq: "en-US"}}) {
      nodes {
        node_locale
        urlName
        body {
          raw
        }
        categories
        createdAt
        description
        id
        title
        updatedAt
        button {
          file {
            url
          }
        }
        recentpost {
          recentPostName
          id
          updatedAt
          order
        }
      }
    }
    allContentfulPageKeywords(filter: {page: {eq: "blogs"}, node_locale: {eq: "en-US"}}) {
        nodes {
            keywords
            node_locale
            page
        }
    }
    allContentfulRecentPost(filter: {blog: {node_locale: {eq: "en-US"}}}) {
      nodes {
        blog {
          id
          node_locale
          title
          urlName
          updatedAt
        }
      }
    }
    contentfulAsset(title: {eq: "blog_banner"}) {
      file {
        url
      }
    }
  }
`

